<template>
  <div class="legal-documents-vue">
    <Header>
      <template v-slot:title>
        {{ $t("legal-documents") }}
      </template>
      <template
        v-if="
          get_profile.terms_and_conditions &&
          get_profile.terms_and_conditions.length > 0
        "
        v-slot:buttons
      >
        <div
          v-if="
            whitelabel !== 'ICMCapital' &&
            whitelabel !== 'ICMMENA' &&
            whitelabel !== 'GCCBrokers' &&
            whitelabel !== 'TradiNext' &&
            whitelabel !== 'VCGMarkets'
          "
        >
          <span
            v-if="get_user_data.show_consent_modal"
            class="inline-flex rounded-md"
          >
            <button
              class="relative inline-flex items-center pr-4 pl-1 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
              type="button"
              @click="showConsentsModal = true"
            >
              <svg
                class="h-5 w-0 mr-2 text-white group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </svg>
              {{ $t("Consent") }}
            </button>
          </span>
          <span v-else class="inline-flex rounded-md">
            <button
              class="relative inline-flex items-center pr-4 pl-1 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
              type="button"
              @click="showRevokeConsentsModal = true"
            >
              <svg
                class="h-5 w-0 mr-2 text-white group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </svg>
              {{ $t("revoke-consent") }}
            </button>
          </span>
        </div>
      </template>
    </Header>
    <div class="min-h-screen max-w-7xl m-auto">
      <div
        v-for="(document, index) in get_profile.terms_and_conditions"
        :key="index"
        class="bg-white relative shadow overflow-hidden sm:rounded-md sm:m-5 mb-3 sm:p-0 p-4"
      >
        <ul>
          <li>
            <a
              class="sm:block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out sm:py-5 sm:px-8"
            >
              <div class="flex items-center">
                <div
                  class="min-w-0 flex-1 sm:flex sm:items-center justify-between"
                >
                  <div class="mr-5 flex">
                    <div
                      class="text-sm leading-5 font-medium text-indigo-600 truncate"
                    >
                      <svg
                        class="legal-document-icon"
                        fill="none"
                        height="40"
                        viewBox="0 0 40 40"
                        width="40"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="20"
                          cy="20"
                          fill="#00B8EE"
                          opacity="0.1"
                          r="20"
                        />
                        <path
                          d="M17 20H23H17ZM17 24H23H17ZM25 29H15C14.4696 29 13.9609 28.7893 13.5858 28.4142C13.2107 28.0391 13 27.5304 13 27V13C13 12.4696 13.2107 11.9609 13.5858 11.5858C13.9609 11.2107 14.4696 11 15 11H20.586C20.8512 11.0001 21.1055 11.1055 21.293 11.293L26.707 16.707C26.8946 16.8945 26.9999 17.1488 27 17.414V27C27 27.5304 26.7893 28.0391 26.4142 28.4142C26.0391 28.7893 25.5304 29 25 29Z"
                          stroke="#00B8EE"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </svg>
                    </div>
                    <div class="ml-5">
                      <div class="text-sm leading-5 font-normal truncate">
                        {{ document.name }}
                        <br />
                        <span class="text-gray-500 font-light">
                          {{
                            document.date_accepted
                              | moment("MM-DD-YYYY, h:mm:ss A")
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="mr-5 sm:mt-0 mt-8">
                    <div
                      class="text-sm leading-5 font-normal text-gray-400 truncate"
                    >
                      <span class="text-gray-600 mr-1"
                        >{{ $t("Version") }}:
                      </span>
                      {{ document.version }}
                    </div>
                  </div>
                  <div
                    class="mt-4 flex-shrink-0 sm:mt-0 bottom-2 right-5 sm:block"
                  >
                    <div class="flex sm:float-right">
                      <a
                        href="https://chsandbox.ctrader.com/ctrader-chsandbox-setup.exe"
                      >
                        <a
                          :href="document.link"
                          class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                          target="_blank"
                        >
                          {{ $t("Download") }}
                        </a>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <consents v-if="showConsentsModal" @close="closeModal"></consents>
      <revoke-consents
        v-if="showRevokeConsentsModal"
        @close="closeModal"
      ></revoke-consents>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Header from "@/components/Header";
import Consents from "@/views/documents/legal-documents/Consents";
import RevokeConsents from "@/views/documents/legal-documents/RevokeConsents";

export default {
  name: "LegalDocuments",
  components: {
    Header,
    Consents,
    RevokeConsents,
  },
  data() {
    return {
      whitelabel: process.env.VUE_APP_WHITELABEL,
      showConsentsModal: false,
      showRevokeConsentsModal: false,
    };
  },
  computed: {
    ...mapGetters(["get_user_data", "get_profile", "get_uploaded_documents"]),
  },
  created() {},
  methods: {
    closeModal() {
      this.showConsentsModal = false;
      this.showRevokeConsentsModal = false;
    },
  },
};
</script>
